import * as React from 'react'
import { Link } from 'gatsby'

export interface HeaderProps {
	title: string,
	headerColor?: 'white' | 'blue',
	searchParams: string
}

class Header extends React.Component<HeaderProps> {
	render() {
		return (
			<header className={"wdc-marketing-header " + (this.props.headerColor === 'blue' ? " wdc-marketing-header-blue" : "")}>
				<div className="wdc-marketing-container">
					<Link className="wdc-marketing-logo" to={`/${this.props.searchParams}`}>
						<span className="wdc-marketing-logo-text">{this.props.title}</span>
					</Link>
				</div>
			</header>
		)
	}

	static defaultProps = {
		bg: 'blue'
	}
}

export default Header
