import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as rehype2react from 'rehype-react'
import registeredComponents from '../components/registered-components'
import Home from '../components/home/home'
import 'what-input'


class IndexPage extends React.Component<IndexPageProps> {
	constructor(props: any) {
		super(props)
	}

	render() {
		const location = this.props.location

		return (
			<Layout location={location} headerColor={'white'}>
				<Home location={location}/>
			</Layout>
		)
	}
}

export default IndexPage
