import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Hero from './home/hero'
import Footer from './footer'

interface LayoutProps {
	title?: string,
	location:  object,
	headerColor?: string
}

class Layout extends React.Component<LayoutProps> {
	constructor(props: LayoutProps) {
		super(props)
	}

	render() {
		const searchParams = this.props.location.search || ''

		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
								favicon
							}
						}
					}
				`}
				render={data => (
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100vh',
					}}>
						<Helmet title={data.site.siteMetadata.title}>
							<link rel="shortcut icon" href={data.site.siteMetadata.favicon} />
						</Helmet>
						<Header title={data.site.siteMetadata.title} searchParams={searchParams} headerColor={this.props.headerColor}/>
						<div
							className="wdc-marketing-container"
							style={{
								flexGrow: 1,
								padding: 0,
								maxWidth: 'initial'
							}}
						>
							{this.props.children}
						</div>
						<Footer />
					</div>
				)}
			/>)
	}
}

export default Layout
