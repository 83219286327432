import * as React from 'react'
import { Link, withPrefix } from 'gatsby'
import { Button, canvas } from '@workday/canvas-kit-react'
import Hero from './hero'
import ReactSVG from 'react-svg'
import NWConstants from '../../utils/constants'

interface HomeProps {location: object}

class Home extends React.Component<HomeProps> {
	constructor(props: HomeProps) {
		super(props)
	}

	render() {
		const cleverIconPath = withPrefix('/images/principle-clever.svg')
		const streamlinePath = withPrefix('/images/workday-for-msteams-demo.png')
		const workdayMSTeamsPath = withPrefix('/images/workday-to-msteams.png')

		return (
			<div>
				<Hero location={this.props.location}/>
				<div className="hero-section home-border-btm">
					<img src={workdayMSTeamsPath} />
					<h4>Accomplish everyday tasks quickly with Workday through Microsoft Teams</h4>
					<p>Give feedback to coworkers, request time off, and enter receipts into Workday without having to leave Microsoft Teams!</p>
				</div>
				<div className="hero-section streamline-section">
					<div>
						<img src={streamlinePath} />
					</div>
					<div className="streamline-content">
						<h4>Streamline Employee Self Service</h4>
						<p>Lookup co-workers details from Workday</p>
					</div>
				</div>
				<div className="wdc-color-bk-blue">
					<div className="hero-section light-bulb-section">
						<div className="light-bulb-content">
							<h4>Together we can empower people to work more intuitively and with less friction.</h4>
							<p>Enable your employees to be more productive and connected.</p>
						</div>
						<div>
							<ReactSVG src={cleverIconPath} />
						</div>
					</div>
				</div>
				<div className="wdc-color-bk-gray border-wrap">
					<div className="hero-section">
						<h4>Interested in trying Workday for Microsoft Teams?</h4>
						<p>Learn more at the Workday Everywhere Community.</p>
						<a target="_blank" href={NWConstants.GA_ARTICLE_URL}><Button buttonType={Button.Types.Secondary}>Visit Now</Button></a>
					</div>
				</div>
			</div>
		)
	}
}

export default Home
