import * as React from 'react'
import ReactSVG from 'react-svg'
import { Button, canvas } from '@workday/canvas-kit-react'
import { Link, withPrefix } from 'gatsby'
import NWConstants from '../../utils/constants'

const demoImagePath = withPrefix('/images/workday-for-msteams.png')

export interface HeroProps {location: object}

class Hero extends React.Component<HeroProps, HeroState> {
	constructor(props: HeroProps) {
		super(props)
	}

	render() {
		const linkTo = `https://appsource.microsoft.com/en-us/product/office/WA200001555`
		const bgPath = withPrefix('/images/home-bg.png')
		const bgStyle = `url(${bgPath}) no-repeat 50%`

		return (
			<div className="hero-bk">
				<div className="hero-wrapper" style={{background: bgStyle, backgroundSize: 'cover'}}>
					<div className="hero-landing-container wdc-marketing-container">
						<div className="hero-landing-content">
							<h1>Workday + Microsoft Teams</h1>
							<p>Leverage the power of Workday from within the tools you depend on.</p>
							<div className="button-container">
								<a target="_blank" href={linkTo}>
									<Button buttonType={Button.Types.Primary}>Add to Microsoft Teams</Button>
								</a>
							</div>
						</div>
						<div className="hero-landing-image">
							<img src={demoImagePath} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Hero
